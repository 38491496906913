.pagination{
    justify-content: center;
    margin: 20px 0;
}
.page-item.active .page-link{
    background-color: var(--blueDark);
    border-color: var(--blueDark);
}
.page-link, .page-link:hover {
    color: var(--blueDark);
}
.page-link:focus {
    box-shadow: none;
}